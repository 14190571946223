import './About.css'
import AboutImage from '../../assets/41.jpg' 
import { FaAward, FaCertificate, FaFolder} from "react-icons/fa";
const About = () => {
  return (
    <section id="about">
    
      <h2>About Us</h2>
    
    <div className="container about_container">
      <div className="about_me">
        <div className="about_me-image">
          <img src={AboutImage} alt="About me" />
        </div>
      </div>
      <div className="about_content">
        <div className="about_cards">
          <article className="about_card">
            <FaAward className="about_icon" />
            <h5>Experience</h5>
            <small>6+ Years Working</small>
          </article>
          <article className="about_card">
            <FaCertificate className="about_icon" />
            <h5>Certificates</h5>
            <small>ISO 9001:2015</small>
          </article>

        </div>
        <p> Experience the confidence that comes with our exceptional calibration services. Our expert team utilizes cutting-edge techniques and advanced equipment to ensure unmatched accuracy and reliability for your instruments. Trust our ISO  9001:2015 Certified  calibration laboratory to deliver precise results that optimize the performance of your equipment. We are committed to maintaining the highest standards of quality and adherence to industry regulations, providing you with the utmost confidence in our services. Enhance your operations with our precision calibration solutions and elevate your productivity to new heights.

        </p>
        
      </div>
    </div>
  </section>
  // <h2>About Us</h2>
  // <p>Experience the confidence that comes with our exceptional calibration services. Our expert team utilizes cutting-edge techniques and advanced equipment to ensure unmatched accuracy and reliability for your instruments. Trust our NABL accredited calibration laboratory to deliver precise results that optimize the performance of your equipment. We are committed to maintaining the highest standards of quality and adherence to industry regulations, providing you with the utmost confidence in our services. Enhance your operations with our precision calibration solutions and elevate your productivity to new heights.

  // </p>
  )
}

export default About
import Logo from'../../assets/picture1.png'

import './navbar.css'
import { useState } from 'react';
import { GoArrowSwitch } from 'react-icons/go';
import { FaHome, FaUser, FaBook, FaProjectDiagram } from "react-icons/fa";
import { MdContactMail } from 'react-icons/md'

const Navbar = () => {
    const[isNavShowing, setIsNavShowing]=useState (false);
    const [activeNav, setActiveNav] = useState('#');
    let hideTimeout;

    const handleNavToggle = () => {
      clearTimeout(hideTimeout); // Clear any previous timeout
      setIsNavShowing(!isNavShowing);
    };
  
    const handleNavLeave = () => {
      hideTimeout = setTimeout(() => {
        setIsNavShowing(false);
      }, 1500);
    };
 
    return (
    <nav>
      <div className="container nav__container">
        <a href='index.html' className='nav__logo'>
            <img src={Logo} alt="Logo" />
        </a>
    
        <ul className={`nav__menu ${isNavShowing ? 'show_nav' : 'hide_nav'}`}>
       {/*    {
            data.map(item => <li  key={item.id} > <a href={item.link}  >{item.titel}</a></li>)
          }  */}
    <li><a href="#" onClick={() => setActiveNav('#about')} className={activeNav === '#' ? 'active' : ''}
    onTouchStart={() => setActiveNav('#')}    ><FaHome /> Home</a></li>
    <li> <a href="#experience"  onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}><FaBook /> Services</a>     </li>
    <li><a href="#services"  onClick={() => setActiveNav('#Services')} className={activeNav === '#Services' ? 'active' : ''}><FaProjectDiagram /> What We Offer</a></li>
     <li> <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><FaUser /> About Us</a></li>
     <li> <a href="#contact"  onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><MdContactMail /> Contact Us</a></li>
    
          
        </ul>
        <button className='nav_toggle-btn' onMouseEnter={handleNavToggle}
          onMouseLeave={handleNavLeave}
          onTouchStart={handleNavToggle}
          onTouchEnd={handleNavLeave}> <GoArrowSwitch/> </button>

              </div>
    </nav>
  )
}

export default Navbar
import './footer.css'
import { FaHome, FaUser, FaBook, FaProjectDiagram } from "react-icons/fa";
import { MdContactMail } from 'react-icons/md'

const Footer = () => {
  return (
    <footer>
    <br/>
    <p>Address-: A 6/6 Mahananda Nagar Ujjain</p>
    
    <br/>
    <ul className='permalinks'>
    
      <li><a href="#" ><FaHome /> Home</a></li>
    <li><a href="#experience"><FaBook /> Services</a>     </li>
    <li><a href="#services"  ><FaProjectDiagram /> What We Offer</a></li>
     <li> <a href="#about" ><FaUser /> About US</a></li>
     <li> <a href="#contact"><MdContactMail /> Contact US</a></li>
    
    </ul>
    <div className="footer_copyright">
      <small>&copy; CY Consult Private Limited. All rights reserved.</small>
    </div>
  </footer>
  )
}

export default Footer
import './testim.css'
import Avt1 from '../../assets/clients.jpg' 

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';




// import required modules
import { Grid,Autoplay, Pagination,  } from 'swiper/modules';

const data=[
{
  avtar: Avt1,
  name: "Zenith Drugs Pvt Ltd"
},
{
  avtar: Avt1,
  name: "Bochem Healthcare Pvt Ltd"
},
{
  avtar: Avt1,
  name: "Indore Sahakari Dugdh Sangh Mydt"
},
{
  avtar: Avt1,
  name: "Kingspan Jindal Pvt Ltd"
},
{
  avtar: Avt1,
  name: "Ultratech Cement Ltd"
},
{
  avtar: Avt1,
  name: "Schneider Electric India Pvt Ltd"
},
{
  avtar: Avt1,
  name: "Panchsheel Organics Ltd"
},
{
  avtar: Avt1,
  name: "Malwa Oxygen and Industrial Gages"
},
{
  avtar: Avt1,
  name: "Super Formulations Pvt Ltd"
},
{
  avtar: Avt1,
  name: "Indian Oil Corporation Ltd"
},
{
  avtar: Avt1,
  name: "Bharat Petroleum Corporation limited"
},
{
  avtar: Avt1,
  name: "Udaipur Beverages Pvt Ltd(Coca_Cola)"
},
{
  avtar: Avt1,
  name: "Panchamrut Dairy(Amul)"
},
{
  avtar: Avt1,
  name: "India Phosphates"
},
{
  avtar: Avt1,
  name: "Accord Pharmaceutical"
},
{
  avtar: Avt1,
  name: "Revacure Lifesciences LLP"
},
{
  avtar: Avt1,
  name: "Lifespan Biotech Pvt. Ltd."
},
]


const Testim = () => {
  return (
    <section id="testim">
      <h2>
       Our Clints
      </h2>
      <br/>
      <br/>
      <Swiper

      slidesPerView={2}
      grid={{
        rows: 1,
          }}
          spaceBetween={20}
      
modules={[Grid, Pagination, Autoplay, Pagination]}
       centeredSlides={true}
       autoplay={{
         delay: 1500,
         disableOnInteraction: false,
       }} 
      className="container testim_container mySwiper"  >
        {
          data.map(({avtar, name },index)=>{
            return(
              <SwiperSlide key={index} className='testimonial'>
              <div className="client_avtar">
                <img src={avtar}  />
              </div>
              <h5 className='h5'>{name}  </h5>
            </SwiperSlide>
            )
          })
        }
      </Swiper>
      
    </section>
  )
}

export default Testim
import './header.css'
import HeaderImage from '../../assets/picture1.png' 

const Header = () => {
  return (
    <header id="header">
      
      <div className="container portfolio_container1">


        <article className='portfolio_item1'>
        <div className="portfolio_item-cta1">
          <br></br>
          <br></br>
          <br></br><h3> CY Consult Private Limited</h3>
           <h4> ISO  9001:2015 Certified Company </h4>
          
         <h5> Cost-Effective Calibration Solutions for Unwavering Reliability</h5>
 </div>
        </article>

     
      </div>
      
    </header>
  )
}

export default Header
import './portfolio.css'
import SiteImage from '../../assets/bg.jpg'
import LabImage from '../../assets/lab.jpg' 
import lab1 from '../../assets/1.jpg'
import lab2 from '../../assets/2.jpg'
import lab3 from '../../assets/3.jpg'
import lab4 from '../../assets/4.jpg'
import lab5 from '../../assets/5.jpg'
import lab from '../../assets/0.jpg' 


const Portfolio = () => {
  return (

    <section id="experience">
    
    <h2>Services</h2>
      <br/>
      <br/>
      <div className="container experience_container">
        <div className="experience_frontend">
          <br/>
          <h3>Laboratory Calibration</h3>
          <div className="experience_content">
            <article className="experience_details">
              <img src={LabImage} className="experience_details-icon" alt="html"/>
              <div>
                <small className="text-light">Precision Calibration for Various Device Types
Whether your devices are numerous or large, our laboratory calibration services are designed to accommodate different requirements. Trust our experienced technicians to provide precise calibration, guaranteeing the accuracy and reliability of your instruments.</small>
              </div>
            </article>
            </div>
        </div>
        <div className="experience_backend">
          <br/>
          <h3> On-Site Calibration</h3>
          <div className="experience_content">
            <article className="experience_details">
              <img src={SiteImage} className="experience_details-icon" alt="postgresql"/>
              <div>
               

                <small className="text-light">Tailored Solutions for Device Quantity and Size
The number and size of your devices determine whether onsite calibration is required. Our expert team delivers customized calibration solutions to meet your specific needs, ensuring optimal performance and accuracy.</small>
              </div>
            </article>
            </div>
        </div>
      </div>

      
    </section>
  )
}

export default Portfolio
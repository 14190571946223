import './services.css'
import{BiCheck} from 'react-icons/bi'

import image1 from '../../assets/image 1.jpg'
import image2 from '../../assets/image 2.jpg'
import image3 from '../../assets/image 3.jpg'
import image4 from '../../assets/image 4 (1).jpg'
import image5 from '../../assets/image 4 (2).jpg'
import image6 from '../../assets/image 4 (3).jpg'
import image7 from '../../assets/image 4 (4).jpg'
import image8 from '../../assets/image 4 (5).jpg'
import image9 from '../../assets/image 4 (6).jpg'
import image10 from '../../assets/image 4 (7).jpg'
import image11 from '../../assets/image 4 (1).png'

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
];


const Services = () => {
  return (
    <section id="services">
      
      
      <h2>what We Offer</h2>
      <br/>
      <br/>
      <div className="container services__container">
        
        <article className='service'>
            <div className='service__head'>

                <h3> Thermal Calibration</h3>

            </div>
            <ul className='service__list'>
              <li>
                <BiCheck className='service__list-icon'/>
                  <p> Temperature Sensors like RTD & thermocuples </p>
              
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                  <p> Temperature Indicator </p>
                 
              </li>   <li>
                <BiCheck className='service__list-icon' />
                  <p> Transmitter </p>
                 
              </li>   <li>
                <BiCheck className='service__list-icon' />
                  <p> Controllers </p>
                 
              </li>   <li>
                <BiCheck className='service__list-icon' />
                  <p> Switches & more-- </p>
                 
              </li> 
            </ul>
            </article>

            <article className='service'>
            <div className='service__head'>

                <h3> Mechanical Calibration</h3>

            </div>
            <ul className='service__list'>
              <li>
                <BiCheck className='service__list-icon' />
                  <p> Pressure Sensor </p>
                 
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                  <p> Pressure Gauges </p>
                 
              </li>   <li>
                <BiCheck className='service__list-icon' />
                  <p> Vacuum Gauges </p>
                 
              </li>   <li>
                <BiCheck className='service__list-icon' />
                  <p> Magnehelic Gauges</p>
                 
              </li>   <li>
                <BiCheck className='service__list-icon' />
                  <p>Transmitters, Switches </p>
                 
              </li> 
              <li>
                <BiCheck className='service__list-icon' />
                  <p>RPM meters  </p>
                 
              </li>   
              <li>
                <BiCheck className='service__list-icon' />
                  <p>Weighing Balances</p>
                 
              </li> 
              <li>
                <BiCheck className='service__list-icon' />
                  <p>Weight Box</p>
                 
              </li>   <li>
                <BiCheck className='service__list-icon'/>
                  <p>Height Gauges, Depth Gauges, Thickness Gauges </p>
                 
              </li> 
              <li>
                <BiCheck className='service__list-icon' />
                  <p>Micrometers </p>
                 
              </li>   <li>
                <BiCheck className='service__list-icon' />
                  <p>vernier calipers</p>
                 
              </li>  
              <li>
                <BiCheck className='service__list-icon' />
                  <p>Dial Gauges</p>
                 
              </li> 
            </ul>
            </article>
            <article className='service'>
            <div className='service__head'>

                <h3> Validation Services</h3>

            </div>
            <ul className='service__list'>
              
              <li>
                <BiCheck className='service__list-icon' />
                  <p> Heat Sterilizers </p>
                 
              </li>   <li>
                <BiCheck className='service__list-icon' />
                  <p>Stability Chambers </p>
                 
              </li>   <li>
                <BiCheck className='service__list-icon' />
                  <p>Ovens </p>
                 
              </li>  
              <li>
                <BiCheck className='service__list-icon' />
                  <p>Validation of HVAC </p>
                 
              </li>  
              <li>
                <BiCheck className='service__list-icon' />
                  <p> Autoclaves  </p>
                 
              </li>  
              <li>
                <BiCheck className='service__list-icon' />
                  <p>Tunnel Sterilizers </p>
                 
              </li>  
              <li>
                <BiCheck className='service__list-icon' />
                  <p>Dry Heat Sterilizers </p>
                 
              </li>  
              <li>
                <BiCheck className='service__list-icon' />
                  <p> LAF</p>
                 
              </li>  
              <li>
                <BiCheck className='service__list-icon' />
                  <p>Compressed Air </p>
                 
              </li> 
            </ul>
            </article>
      </div>
      <div classname="g">
       <div className="gallery">
       {images.map((src, index) => (
         <div className="gallery-item" key={index}>
           <img src={src} alt={`Gallery item ${index + 1}`} />
         </div>
       ))}
     </div></div>
     
    </section>
  )
}

export default Services
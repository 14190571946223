import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {BsWhatsapp} from 'react-icons/bs'
import {FcGlobe} from 'react-icons/fc'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ungghfn', 'template_o0mihmv', form.current, 'x-tE1PV7jC1-93nuo')
        .then((result) => {
          
          console.log("message sent");
          alert('Message sent Successfully')
          
        }, (error) => {
           console.log(error.text);
           alert('error Occurred Please send Mail To navnathtech@gmail.com ')
           
  });
    e.target.reset()
};

  return (
    <section id="contact">
      
      <h2>Contact Us</h2>
      <br/>
      <br/>
        <div className="container contact_container">
          <div className="contact_options">
            <article className='contact_option'>
              <MdOutlineEmail className="contact_icon"/>
              <h4> </h4>
                <a href='mailto:cyindia01@gmail.com' target="_blank">
                  <h4> Email</h4>
                  <h5> cyindia01@gmail.com</h5>
                  Send a message</a>  
            </article>
            <article className='contact_option'>
              <BsWhatsapp className="contact_icon"/>
              
              <h5> </h5>
                <a href='https://api.whatsapp.com/send?phone=919039369009' target="_blank">
                <h4> Whatsapp</h4>
              <h5> +91-9039369009</h5>
                  Send a message</a>  
            </article>
            <article className='contact_option'>
             <FcGlobe className="contact_icon"/>
             <h5> </h5>
                <a href='https://maps.app.goo.gl/YWovop66r3dVC9vC7' target="_blank">
                <h4> Click to   Get</h4>
              <h5> Our Location</h5></a>  
            </article> 
          </div>
            < form  ref={form} onSubmit={sendEmail} >
            <input type="text" name="name" placeholder="Your Full Name" required/> 
            <input type="email" name="email" placeholder="Email" required/>
            <input type="number" name="number" placeholder="Contact No." required/>
            <textarea name="messsage" rows='7' placeholder="Your Message" required></textarea>
            <button type="submit" value="Send"  className="btn btn-primary"> Send Message</button>

            </form>
        </div>

    </section>
  )
}

export default Contact